@font-face {
    font-family: 'taktik';
    src: url('./font/icomoon.eot?z9603s');
    src: url('./font/icomoon.eot?z9603s#iefix') format('embedded-opentype'), 
        url('./font/icomoon.woff2?z9603s') format('woff2'), 
        url('./font/icomoon.ttf?z9603s') format('truetype'), 
        url('./font/icomoon.woff?z9603s') format('woff'), 
        url('./font/icomoon.svg?z9603s#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[data-icon]:before, [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: 'taktik' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
    content: attr(data-icon);
}

.icon-informatika:before {
    content: "\25a1";
}

.icon-messages:before {
    content: "\29c9";
}

.icon-smajlik:before {
    content: "\263a";
}

.icon-great-britain:before {
    content: "\a3";
}

.icon-arrow-clockwise:before {
    content: "\61";
}

.icon-arrow-down:before {
    content: "\62";
}

.icon-arrow-down-narrow:before {
    content: "\63";
}

.icon-arrow-down-round:before {
    content: "\64";
}

.icon-arrow-down-thin:before {
    content: "\65";
}

.icon-arrow-down-wide:before {
    content: "\66";
}

.icon-arrow-left:before {
    content: "\67";
}

.icon-arrow-left-narrow:before {
    content: "\68";
}

.icon-arrow-left-round:before {
    content: "\69";
}

.icon-arrow-left-thin:before {
    content: "\6a";
}

.icon-arrow-left-wide:before {
    content: "\6b";
}

.icon-arrow-right:before {
    content: "\6c";
}

.icon-arrow-right-narrow:before {
    content: "\6d";
}

.icon-arrow-right-round:before {
    content: "\6e";
}

.icon-arrow-right-thin:before {
    content: "\6f";
}

.icon-arrow-right-wide:before {
    content: "\70";
}

.icon-arrow-up:before {
    content: "\71";
}

.icon-arrow-up-narrow:before {
    content: "\72";
}

.icon-arrow-up-round:before {
    content: "\73";
}

.icon-arrow-up-thin:before {
    content: "\74";
}

.icon-arrow-up-wide:before {
    content: "\75";
}

.icon-atom:before {
    content: "\76";
}

.icon-book:before {
    content: "\77";
}

.icon-book-light:before {
    content: "\78";
}

.icon-brain:before {
    content: "\79";
}

.icon-butterfly:before {
    content: "\7a";
}

.icon-calculator:before {
    content: "\41";
}

.icon-check:before {
    content: "\42";
}

.icon-check-round:before {
    content: "\43";
}

.icon-close:before {
    content: "\44";
}

.icon-columns:before {
    content: "\45";
}

.icon-cow:before {
    content: "\46";
}

.icon-crown:before {
    content: "\47";
}

.icon-czech-republic:before {
    content: "\48";
}

.icon-earth:before {
    content: "\49";
}

.icon-eraser:before {
    content: "\4a";
}

.icon-exclamation-mark:before {
    content: "\4b";
}

.icon-full-screen:before {
    content: "\4c";
}

.icon-leaf:before {
    content: "\4d";
}

.icon-letter:before {
    content: "\4e";
}

.icon-letters:before {
    content: "\4f";
}

.icon-lock:before {
    content: "\50";
}

.icon-mortar-board:before {
    content: "\51";
}

.icon-note:before {
    content: "\52";
}

.icon-page-edit:before {
    content: "\53";
}

.icon-pages:before {
    content: "\e001";
}

.icon-pen:before {
    content: "\55";
}

.icon-pencil:before {
    content: "\56";
}

.icon-plus:before {
    content: "\57";
}

.icon-puzzle:before {
    content: "\58";
}

.icon-question-mark:before {
    content: "\59";
}

.icon-reading:before {
    content: "\5a";
}

.icon-retort:before {
    content: "\30";
}

.icon-star:before {
    content: "\31";
}

.icon-taktik-logo:before {
    content: "\32";
}

.icon-taktik-logo-text:before {
    content: "\33";
}

.icon-teacher:before {
    content: "\34";
}

.icon-user:before {
    content: "\35";
}

.icon-video-play:before {
    content: "\36";
}

.icon-zoom:before {
    content: "\37";
}

.icon-zoom-in:before {
    content: "\38";
}

.icon-zoom-out:before {
    content: "\39";
}

.icon-checkbox:before {
    content: "\21";
}

.icon-cogs:before {
    content: "\22";
}

.icon-comment:before {
    content: "\23";
}

.icon-log-in:before {
    content: "\24";
}

.icon-home:before {
    content: "\25";
}

.icon-bars:before {
    content: "\26";
}

.icon-file-o:before {
    content: "\27";
}

.icon-folder-o:before {
    content: "\2a";
}

.icon-sort:before {
    content: "\2b";
}

.icon-bookmark-o:before {
    content: "\2c";
}

.icon-crosshairs:before {
    content: "\2e";
}

.icon-play-circle:before {
    content: "\28";
}

.icon-arrows:before {
    content: "\29";
}

.icon-arrows-h:before {
    content: "\2d";
}

.icon-arrows-v:before {
    content: "\2f";
}

.icon-code-fork:before {
    content: "\3a";
}

.icon-android-expand:before {
    content: "\3b";
}

.icon-benefit-icon1:before {
    content: "\3c";
}

.icon-benefit-icon2:before {
    content: "\3d";
}

.icon-benefit-icon3:before {
    content: "\3e";
}

.icon-keyboard:before {
    content: "\3f";
}

.icon-log-out:before {
    content: "\40";
}

.icon-flag:before {
    content: "\5b";
}

.icon-flag-1:before {
    content: "\5d";
}

.icon-flag-o:before {
    content: "\5e";
}

.icon-flag-checkered:before {
    content: "\5f";
}

.icon-volume-up:before {
    content: "\60";
}

.icon-volume-off:before {
    content: "\7b";
}

.icon-volume-down:before {
    content: "\7c";
}

.icon-play:before {
    content: "\7d";
}

.icon-music:before {
    content: "\7e";
}

.icon-pause:before {
    content: "\5c";
}

.icon-cart:before {
    content: "\e000";
}

.icon-vydavatelstvo-taktik-logo:before {
    content: "\e002";
}

.icon-technika-icon:before {
    content: "\54";
}

.icon-merge-fill:before {
    content: "\e003";
}

.icon-merge-stroke:before {
    content: "\e004";
}