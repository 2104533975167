a {
	text-decoration: none;
}

body {
	overflow-x: hidden;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n4&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n6&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n7&v=3") format("opentype");
	font-display: auto;
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i4&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff2"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("woff"), url("https://use.typekit.net/af/5b5251/00000000000000003b9b3065/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i5&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 500;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff2"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("woff"), url("https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i6&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 600;
}

@font-face {
	font-family: "proxima-nova";
	src: url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=i7&v=3") format("opentype");
	font-display: auto;
	font-style: italic;
	font-weight: 700;
}

@-webkit-keyframes pointer1 {
	0% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(254, 198, 61, 0);
	}

	10% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 1), 0 0 12px 10px transparent, 0 0 12px 14px rgba(254, 198, 61, 1);
	}

	100% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(254, 198, 61, 0);
	}
}

@-moz-keyframes pointer1 {
	0% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(254, 198, 61, 0);
	}

	10% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 1), 0 0 12px 10px transparent, 0 0 12px 14px rgba(254, 198, 61, 1);
	}

	100% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(254, 198, 61, 0);
	}
}

@keyframes pointer1 {
	0% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 0px 0px transparent, 0 0 0px 0px rgba(254, 198, 61, 0);
	}

	10% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 1), 0 0 12px 10px transparent, 0 0 12px 14px rgba(254, 198, 61, 1);
	}

	100% {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 0px 40px transparent, 0 0 0px 40px rgba(254, 198, 61, 0);
	}
}

@-webkit-keyframes pointer1h {
	from {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 1), 0 0 12px 10px transparent, 0 0 12px 14px rgba(254, 198, 61, 1);
	}

	to {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 4px 40px transparent, 0 0 4px 41px rgba(254, 198, 61, 0);
	}
}

@-moz-keyframes pointer1h {
	from {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 1), 0 0 12px 10px transparent, 0 0 12px 14px rgba(254, 198, 61, 1);
	}

	to {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 4px 40px transparent, 0 0 4px 41px rgba(254, 198, 61, 0);
	}
}

@keyframes pointer1h {
	from {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 1), 0 0 12px 10px transparent, 0 0 12px 14px rgba(254, 198, 61, 1);
	}

	to {
		box-shadow: 0 0 8px 6px rgba(254, 198, 61, 0), 0 0 4px 40px transparent, 0 0 4px 41px rgba(254, 198, 61, 0);
	}
}

.MuiTableBody-root .MuiTableRow-root:hover td {
	background: #f5f5f5;
}